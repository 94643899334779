'use strict';
import $ from 'jquery';
export default class Header {
  constructor() {
    this.name = 'header';
    
    $( "#burger" ).click(function() {
      console.log('module');
      $( "#burger-close" ).show();
        $( "#burger" ).hide();
      $( ".topmenu" ).slideDown( "slow", function() {
        
      });
    });
    $( "#burger-close" ).click(function() {
      $( "#burger" ).show();
        $( "#burger-close" ).hide();
      console.log('module');
      $( ".topmenu" ).slideUp( "slow", function() {
        // Animation complete.
      });
    });
  }
}
